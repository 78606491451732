import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import styles from "./PoliticsPage.module.css";
import demoVideo from "../../assets/demo.mp4";
import Logo from "../../assets/Contoso.svg";
import { Navigate } from 'react-router-dom';

const PoliticsPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.containerContent}>
                <img src={Logo} alt="Contoso Logo" className={styles.logo} />
                <h1 className={styles.header}>Data Politik</h1>
                <p className={styles.text}>
                    <strong>1. Introduktion</strong>
                    <br />
                    Hos Moeller Group ApS værdsætter vi dit privatliv og er forpligtede til at beskytte dine personlige data. Denne politik beskriver, hvordan vi indsamler, bruger, opbevarer og beskytter dine oplysninger, når du bruger vores SaaS-platform.
                    <br /><br />
                    <strong>2. Dataindsamling</strong>
                    <br />
                    Vi indsamler personlige oplysninger, som du frivilligt giver os, såsom e-mailadresse, kontaktinformationer og betalingsoplysninger. Vi kan også automatisk indsamle data om din brug af vores tjenester, såsom login-oplysninger, tidspunkt for adgang, antal forespørgsler til JuraChat.
                    <br />
                    Vi sletter dine personlige oplysninger samt konto, så snart du anmoder om det, eller når dit abonnement er udløbet.
                    <br />
                    Vi logger eller gemmer ikke dine input til JuraChat ligesom vi ligeledes ikke gemmer output fra JuraChat. Dette sikrer at dine input til JuraChat ikke bliver benyttet andetsteds, hvorfor vi heller ikke kan tilbyde en chat-historik på platformen.
                    <br /><br />
                    <strong>3. Formål med Dataanvendelse</strong>
                    <br />
                    De indsamlede data bruges til at levere og forbedre vores tjenester, kommunikere med dig, behandle betalinger, og til interne analyser. Vi bruger ikke dine oplysninger til markedsføring uden dit udtrykkelige samtykke.
                    <br /><br />
                    <strong>4. Datasikkerhed</strong>
                    <br />
                    Vi anvender industri-standard sikkerhedsforanstaltninger for at beskytte dine data mod uautoriseret adgang, ændring, videregivelse eller ødelæggelse. Vi opbevarer data på sikre servere og benytter kryptering, når det er passende.
                    <br /><br />
                    <strong>5. Videregivelse af Data</strong>
                    <br />
                    Vi deler ikke dine personlige oplysninger med tredjeparter, medmindre det er nødvendigt for at levere vores tjenester, opfylde lovgivningsmæssige krav, eller beskytte vores rettigheder.
                    <br /><br />
                    <strong>6. Dine Rettigheder</strong>
                    <br />
                    Du har ret til at anmode om adgang til, rettelse eller sletning af dine personlige data. Du kan også anmode om begrænsning af behandlingen af dine data eller gøre indsigelse mod denne behandling.
                    <br /><br />
                    <strong>7. Ændringer i Datapolitikken</strong>
                    <br />
                    Vi forbeholder os retten til at ændre denne politik. Enhver ændring vil blive kommunikeret på vores platform, og vi anbefaler, at du regelmæssigt gennemgår denne politik.
                    <br /><br />
                    <strong>8. Kontakt</strong>
                    <br />
                    For spørgsmål vedrørende denne datapolitik, kontakt os venligst på .
                </p>
            </div>
        </div>
    );
};

export default PoliticsPage;
