// src/index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import App from "./App";
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

// Initialize Fluent UI icons for use throughout the app
initializeIcons();

// Create an instance of PublicClientApplication with the provided configuration
export const msalInstance = new PublicClientApplication(msalConfig);

// Optionally, set an initial active account if one exists in cache
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

// Listen for login success events to set the active account
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

// Render the App component, passing the MSAL instance to it
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App pca={msalInstance} />);
