// Browser check variables to determine the best sign-in method based on the browser
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only if you need to support the redirect flow in Firefox incognito

/**
 * Configuration for Azure AD B2C user flows and custom policies
 */
export const b2cPolicies = {
    names: {
        signIn: "B2C_1_SignIn",
        editProfile: "B2C_1_ProfileEditPolicy"
    },
    authorities: {
        editProfile: {
            authority: "https://jurachat.b2clogin.com/jurachat.onmicrosoft.com/B2C_1_ProfileEditPolicy",
        },
        signIn: "https://jurachat.b2clogin.com/jurachat.onmicrosoft.com/B2C_1_SignIn"
    },
    authorityDomain: "jurachat.b2clogin.com"
};

// MSAL configuration
export const msalConfig = {
    auth: {
        clientId: "24f61b0f-75be-4a30-8b9c-df08ef24bd9e",
        authority: b2cPolicies.authorities.signIn,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin, // For local development, this will be http://localhost:50505
        postLogoutRedirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};

// Login request configuration
export const loginRequest = {
    scopes: ["openid", "offline_access", "profile", "https://jurachat.onmicrosoft.com/jurachatapi/read"],
    // You might need additional scopes here depending on your application's requirements
};

// API configuration for accessing your backend services
export const apiConfig = {
    scopes: ['https://jurachat.onmicrosoft.com/jurachatapi/read'], // Define scopes needed to access your API
    uri: 'https://jurachat.onmicrosoft.com/jurachatapi', // The endpoint URL of your API
};

// Additional helper function or configuration can be added below
