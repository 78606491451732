import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { BookSearch20Filled, Pen20Filled, BookmarkSearch20Filled } from '@fluentui/react-icons';
import styles from "./FrontPage.module.css";
import { Navigate } from 'react-router-dom';
import contactImage from "../../assets/contact.png";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const typingExamples = [
    "Hej, mit navn er JuraChat. Jeg er en AI, der kan hjælpe dig med at finde information i juridiske dokumenter.",
];

const TypingAnimation = () => {
    const [displayText, setDisplayText] = useState("");
    const [exampleIndex, setExampleIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayText((prev) => prev + typingExamples[exampleIndex].charAt(charIndex));
            setCharIndex((prev) => prev + 1);
        }, 100);

        if (charIndex === typingExamples[exampleIndex].length) {
            clearInterval(interval);
            setTimeout(() => {
                setDisplayText("");
                setCharIndex(0);
                setExampleIndex((prev) => (prev + 1) % typingExamples.length);
            }, 2000);
        }

        return () => clearInterval(interval);
    }, [charIndex, exampleIndex]);

    return (
        <p className={styles.typingAnimation}>
            {displayText.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </p>
    );
};

const FrontPage = () => {
    const { instance } = useMsal();

    const handleLogin = () => instance.loginRedirect(loginRequest);

    return (
        <div className={styles.fullPageContainer}>
            <AuthenticatedTemplate>
                <Navigate to="/chat" replace />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <div className={styles.mainContent}>
                    <div className={styles.textContainer}>
                        <h1 className={styles.title}>Find hurtigt information i juridiske dokumenter</h1>
                        <p className={styles.subtitle}>
                            JuraChat er <span className={styles.gradientText}>drevet af AI</span>, til intelligent at søge i Juridiske tekster og hjælpe med at formulere juridiske tekster
                        </p>
                        <div className={styles.buttonContainer}>
                            <a href="mailto:info@solutionz4u.dk?subject=Adgang%20til%20JuraChat" className={styles.getStartedButton}>Kom igang</a>
                            <div className={styles.talkButtonContainer}>
                                <a href="mailto:info@solutionz4u.dk?subject=Let's%20Talk%20About%20JuraChat" className={styles.talkButton}>
                                    <img src={contactImage} alt="Contact" className={styles.contactImage} />
                                    Lad os snakke?
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.conversationWrapper}>
                        <div className={styles.conversationContainer}>
                            <div className={styles.userBox}>
                                <p className={styles.userText}>Hvad kan du hjælpe med?</p>
                            </div>
                            <div className={styles.paddingBox}>
                                <div className={styles.typingBox}>
                                    <TypingAnimation />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.newSection}>
                    <h1 className={styles.newSectionTitle}>Hvorfor benytte JuraChat</h1>
                    <p className={styles.newSectionSubtitle}>AI-assistent, der besvarer dine spørgsmål hurtigt og præcist</p>
                    <button className={styles.goToProductButton} onClick={handleLogin}>Gå til JuraChat</button>
                    <div className={styles.features}>
                        <div className={styles.feature}>
                            <h3 className={styles.featureTitle}>
                                <BookSearch20Filled className={styles.icon} /> Smart søgning
                            </h3>
                            <p className={styles.featureDescription}>
                                I stedet for at bruge tid på at gennemgå lovsamlinger og databaser manuelt, kan du med JuraChat blot skrive et spørgsmål og få præcise juridiske oplysninger med det samme. Dette sparer tid og sikrer, at du hurtigt får adgang til de mest relevante kilder.
                            </p>
                        </div>
                        <div className={styles.feature}>
                            <h3 className={styles.featureTitle}>
                                <Pen20Filled className={styles.icon} /> Hurtig tekstproduktion
                            </h3>
                            <p className={styles.featureDescription}>
                                JuraChat kan bruges til at skrive juridiske tekster hurtigere ved hjælp af den fremsøgte information. På sigt bliver JuraChat også trænet på danske juridiske tekster, så den bliver endnu bedre til at skrive korrekt dansk juridisk sprog.
                            </p>
                        </div>
                        <div className={styles.feature}>
                            <h3 className={styles.featureTitle}>
                                <BookmarkSearch20Filled className={styles.icon} /> Præcise referencer
                            </h3>
                            <p className={styles.featureDescription}>
                                JuraChat laver referencer til de kilder, den benytter, så brugerne kan verificere svarenes korrekthed.
                            </p>
                        </div>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default FrontPage;
