import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';

// Import your application components and state provider
import Layout from './pages/layout/Layout';
import NoPage from './pages/NoPage';
import Chat from './pages/chat/Chat';
import FrontPage from './pages/frontpage/FrontPage';
import AboutPage from './pages/about/AboutPage';
import TermsPage from './pages/terms/TermsPage';
import PoliticsPage from './pages/politics/PoliticsPage';

import { AppStateProvider } from './state/AppProvider';
import { CustomNavigationClient } from './utils/NavigationClient';

type AppProps = {
    pca: IPublicClientApplication;
};

const MsalNavigationProvider = ({ pca }: AppProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        const navigationClient = new CustomNavigationClient(navigate);
        pca.setNavigationClient(navigationClient);
    }, [navigate, pca]);

    return null; // This component does not render anything
};

const App = ({ pca }: AppProps) => {
    return (
        <MsalProvider instance={pca}>
            <AppStateProvider>
                <BrowserRouter>
                    {/* Insert the navigation provider right inside the router */}
                    <MsalNavigationProvider pca={pca} />
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<FrontPage />} />
                            <Route path="chat" element={<Chat />} />
                            <Route path="about" element={<AboutPage />} />
                            <Route path="terms" element={<TermsPage />} />
                            <Route path="politics" element={<PoliticsPage />} />
                            <Route path="*" element={<NoPage />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AppStateProvider>
        </MsalProvider>
    );
};

export default App;
