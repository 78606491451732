import React, { useEffect, useState } from 'react';
import styles from './Footer.module.css';
import { Link, useLocation } from 'react-router-dom';
import { MdLocationOn, MdEmail, MdLocalPhone } from 'react-icons/md';
import { IoIosDocument } from 'react-icons/io';
import { FaUsers, FaBuilding } from 'react-icons/fa';

export function Footer() {
  const location = useLocation();
  const [isChatPage, setIsChatPage] = useState(false);

  useEffect(() => {
    setIsChatPage(location.pathname === '/chat');
  }, [location]);

  return (
    <footer className={`${styles.footer} ${isChatPage ? styles.chatPage : ''}`}>
      {!isChatPage && <div className={styles.horizontalLine}></div>}
      <div className={styles.columns}>
        <div className={styles.section}>
          <h5 className={styles.title}>ORGANISATION</h5>
          <Link to="/about" className={styles.listLink}> 
            <div className={styles.item}>
              <FaUsers className={styles.icon} />
              <span className={styles.listLinkText}>Om os</span>
            </div>
          </Link>
          <div className={styles.item}>
            <MdLocationOn className={styles.icon} />
            <span>Herlev</span>
          </div>
          <div className={styles.item}>
            <FaBuilding className={styles.icon} />
            <span>Solutionz 4U</span>
          </div>
        </div>
        <div className={styles.section}>
          <h5 className={styles.title}>KONTAKT OS</h5>
          <div className={styles.item}>
            <MdEmail className={styles.icon} />
            <a href="mailto:info@solutionz4u.dk" className={styles.listLink}>Email</a>
          </div>
          <div className={styles.item}>
            <MdLocalPhone className={styles.icon} />
            <a href="tel:+4540152507" className={styles.listLink}>Ring til os</a>
          </div>
        </div>
        <div className={styles.section}>
          <h5 className={styles.title}>VIGTIG INFORMATION</h5>
          <Link to="/terms" className={styles.listLink}> 
            <div className={styles.item}>
              <IoIosDocument className={styles.icon} />
              <span className={styles.listLinkText}>Servicevilkår</span>
            </div>
          </Link>
          <Link to="/politics" className={styles.listLink}> 
            <div className={styles.item}>
              <IoIosDocument className={styles.icon} />
              <span className={styles.listLinkText}>Data Politik</span>
            </div>
          </Link>
        </div>
      </div>
      <div className={styles.copyRightSection}>
        <span>© 2024 Moeller Group ApS</span>
      </div>
    </footer>
  );
}
