import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import styles from "./AboutPage.module.css";
import demoVideo from "../../assets/demo.mp4";
import Logo from "../../assets/Contoso.svg";
import { Navigate } from 'react-router-dom';

const AboutPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.containerContent}>
                <img src={Logo} alt="Contoso Logo" className={styles.logo} />
                <h1 className={styles.header}>Om os</h1>
                <p className={styles.text}>
                    Vi er et hold af unge AI udviklere, som mener, at måden jurister tilgår juridisk information i Danmark simpelthen er forældet! Vi
                    mener, at med de nye AI værktøjer, som sprogmodeller (LLM), er vi i stand til at gøre det muligt at forbedre arbejdet for jurister 
                    på en række områder:
                </p>
                <ul className={styles.text}>
                    <li>Vi kan træne en sprogmodel til at skrive som danske jurister og dermed målrette output fra sprogmodellen til at tilpasse sproget
                        til det danske jurister benytter.</li>
                    <li>Vi vil gøre det nemmere at finde relevant data ved at lade JuraChat søge i data for juristerne. På den måde kan processen ved at 
                        finde relevante domme og love effektiviseres betydeligt!</li>
                    <li>Vi kan lave referencer til det data, som JuraChat benytter til at lave svar for på den måde at give brugerne mulighed for at
                        validere det output, som JuraChat genererer.</li>
                </ul>
                <p className={styles.text}>
                    Vi udvikler konstant på JuraChat og følger de vigtigste tendenser i branchen for at sikre løsningens relevans og forbedre den, når det er
                    muligt. Vi håber I vil prøve kræfter med vores platform.
                </p>
                <p className={styles.text}>
                    Vi kan kontaktes på <a href="mailto:info@solutionz4u.dk" className={styles.emailLink}>info@solutionz4u.dk</a> for anmodning om adgang. 
                </p>
            </div>
        </div>
    );
};

export default AboutPage;
