import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import styles from "./TermsPage.module.css";
import demoVideo from "../../assets/demo.mp4";
import Logo from "../../assets/Contoso.svg";
import { Navigate } from 'react-router-dom';

const TermsPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.containerContent}>
                <img src={Logo} alt="Contoso Logo" className={styles.logo} />
                <h1 className={styles.header}>Servicevilkår</h1>
                <p className={styles.text}>
                    <strong>1. Accept af Vilkår</strong>
                    <br />
                    Ved at tilgå og/eller bruge JuraChat platformen, accepterer du disse vilkår og betingelser i sin helhed. Hvis du er uenig med disse vilkår og betingelser eller nogen del heraf, må du ikke bruge JuraChat platformen.
                    <br /><br /><br />
                    <strong>2. Svar fra JuraChat</strong>
                    <br />
                    JuraChat genererer tekst vha. en stor sprogmodel og opslag i vores interne database af love og domme. Svarene fra JuraChat kan benyttes til en lang række formål.
                    <br />
                    Brugere forstår og accepterer, at svarene fra JuraChat kan være underlagt fejl, og at Moeller Group ApS ikke er ansvarlig for nogen fejl, unøjagtigheder eller problemer, der måtte opstå som følge heraf.
                    <br />
                    Brugeren påtager sig fuldt ansvar for at verificere nøjagtigheden og egnetheden af svarene fra JuraChat før brug i projekter eller andre formål.
                    <br /><br /><br />
                    <strong>3. Ansvarsfraskrivelse</strong>
                    <br />
                    Moeller Group ApS fraskriver sig ethvert ansvar for skader, tab eller krav, der opstår som følge af brug af JuraChat.
                    <br /><br /><br />
                    <strong>4. Ændringer i Vilkår</strong>
                    <br />
                    Moeller Group ApS forbeholder sig retten til at ændre disse vilkår og betingelser til enhver tid ved at opdatere denne tekst. Det er dit ansvar regelmæssigt at gennemgå disse vilkår og betingelser for eventuelle ændringer.
                    <br /><br /><br />
                    <strong>5. Gældende Lov</strong>
                    <br />
                    Disse vilkår og betingelser er underlagt og fortolket i overensstemmelse med lovgivningen i Danmark.
                    <br /><br /><br />
                    <strong>6. Abonnement og Betaling</strong>
                    <br />
                    JuraChat platformen tilbyder forskellige abonnementsplaner, inklusive månedlige og årlige planer, med en fastsat mængde af forbrug inkluderet. Ved at vælge en abonnementsplan accepterer du at betale det tilhørende gebyr, som vil blive faktureret på forhånd i henhold til den valgte plan.
                    <br />
                    Udover de månedlige og årlige abonnementsplaner tilbyder JuraChat platformen også muligheden for engangsbetaling for ekstra forbrug ud over det inkluderede forbrug i den evt. valgte abonnementsplan. Dit månedlige forbrug på en abonnements plan vil altid blive brugt før dit ekstra forbrug.
                    <br /><br /><br />
                    <strong>7. Økonomisk Ansvar</strong>
                    <br />
                    Ved at tilmelde dig en betalingsplan, accepterer du det fulde økonomiske ansvar for alle gebyrer, der påløber ved brug af tjenesten. Når du køber en mængde ekstra forbrug, accepterer du engangsgebyret for det ekstra forbrug.
                    <br /><br /><br />
                    <strong>8. Ændringer i Betalingsvilkår</strong>
                    <br />
                    Moeller Group ApS forbeholder sig retten til at til enhver tid at ændre priserne på abonnementer og for yderligere forbrug. Ændringerne vil blive meddelt i forvejen og træde i kraft fra næste faktureringscyklus.
                </p>
            </div>
        </div>
    );
};

export default TermsPage;
