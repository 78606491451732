import { Outlet, Link, useLocation } from "react-router-dom";
import styles from "./Layout.module.css";
import Contoso from "../../assets/Contoso.svg";
import { useContext, useState, useEffect } from "react";
import { Stack, Dialog, TextField } from "@fluentui/react";
import { CopyRegular, ChevronRight20Filled } from "@fluentui/react-icons";
import { AppStateContext } from "../../state/AppProvider";
import { Footer } from "./Footer";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const Layout = () => {
    const [isSharePanelOpen, setIsSharePanelOpen] = useState(false);
    const [copyClicked, setCopyClicked] = useState(false);
    const [copyText, setCopyText] = useState("Copy URL");
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const appStateContext = useContext(AppStateContext);
    const ui = appStateContext?.state.frontendSettings?.ui;
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated(); // Use MSAL's hook to determine authentication state
    const location = useLocation();

    const handleLogin = () => instance.loginRedirect(loginRequest);
    const handleSignOut = () => instance.logout(); // Changed to logout() for simplicity, adjust as needed
    const handleGetStarted = () => {
        window.location.href = "mailto:info@solutionz4u.dk?subject=Adgang%20til%20JuraChat";
    };

    const handleShareClick = () => setIsSharePanelOpen(true);
    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText("Copy URL");
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopyClicked(true);
    };

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector(`.${styles.header}`);
            if (window.scrollY > 0) {
                header?.classList.add(styles.scrolled);
            } else {
                header?.classList.remove(styles.scrolled);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const isChatPage = location.pathname === "/chat";

    return (
        <div className={styles.layout}>
            <header className={`${styles.header} ${isChatPage ? styles.staticHeader : ""}`} role="banner">
                <div className={styles.headerContent}>
                    <Stack horizontal verticalAlign="center" className={styles.headerContainer}>
                        <Link to="/" className={styles.headerIconLink}>
                            <img src={ui?.logo ? ui.logo : Contoso} className={styles.headerIcon} aria-hidden="true" />
                        </Link>
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h1 className={styles.headerTitle}>{ui?.title}</h1>
                        </Link>
                    </Stack>
                    <button className={styles.hamburgerButton} onClick={toggleMobileMenu}>
                        ☰
                    </button>
                    <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.buttonStack}>
                        {!isAuthenticated ? (
                            <button onClick={handleLogin} className={styles.authButton}>Log Ind</button>
                        ) : (
                            <button onClick={handleSignOut} className={styles.authButton}>Log Ud</button>
                        )}
                        <button onClick={handleGetStarted} className={styles.getStartedButton}>
                            Kom Igang <ChevronRight20Filled className={styles.icon} />
                        </button>
                    </Stack>
                </div>
                {isMobileMenuOpen && (
                    <div className={styles.mobileMenu}>
                        <ul>
                            {!isAuthenticated ? (
                                <li onClick={handleLogin} className={styles.navItem}>Log Ind</li>
                            ) : (
                                <li onClick={handleSignOut} className={styles.navItem}>Log Ud</li>
                            )}
                            <li onClick={handleGetStarted} className={styles.navItem}>Kom Igang</li>
                        </ul>
                    </div>
                )}
            </header>
            <Outlet />
            <Footer />
            <Dialog
                onDismiss={handleSharePanelDismiss}
                hidden={!isSharePanelOpen}
                dialogContentProps={{
                    title: "Share the web app",
                    showCloseButton: true,
                }}
                styles={{
                    main: {
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '200px',
                                minHeight: '100px',
                            },
                        },
                    },
                }}
            >
                <Stack horizontal verticalAlign="center" style={{ gap: "8px" }}>
                    <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly />
                    <div
                        className={styles.copyButtonContainer}
                        role="button"
                        tabIndex={0}
                        aria-label="Copy"
                        onClick={handleCopyClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                    >
                        <CopyRegular className={styles.copyButton} />
                        <span className={styles.copyButtonText}>{copyText}</span>
                    </div>
                </Stack>
            </Dialog>
        </div>
    );
};

export default Layout;
